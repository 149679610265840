import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 12" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 12</h1>
      <p>For en herlig og spennende lørdag! I dag skal være en mer opplevelsesrik dag enn jeg noensinne kanskje har opplevd før!</p>
      <p>Til slike opplevelser tror jeg det er lurt å spise rikelig for å ha mest energi, så jeg skal prøve å være flink i dag, lover!</p>
      <p>Dagens ekstra gave har jeg gjemt et lurt sted! Den er på rommet mitt, og jeg skal si tampen brenner når du nærmer deg den.</p>
      <p>Let's go treasure hunting!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
